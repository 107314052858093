import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex';
import type { State } from '../index';

export interface UsersState {
    token: string,
    user: any,
    menus: any,
}

const state: UsersState = {
    token: '',
    user: '',
    menus: []
};

const mutations: MutationTree<UsersState> = {
    updateToken(state, payload) {
        state.token = payload;
    },
    updateUser(state, payload) {
        state.user = payload;
    },
    updateMenus(state, payload) {
        state.menus = payload;
    },
    clearToken(state) {
        state.token = '';
        state.user = '';
    }
};
const actions: ActionTree<UsersState, State> = {
    login(context, payload) {
        return http.post('admin/SystemUser/login', payload)
    }
};
const getters: GetterTree<UsersState, State> = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}