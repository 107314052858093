import axios from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import type { StateAll } from '@/store'

const instance = axios.create({
    // baseURL: 'https://kxd-services.hhyp58.com/', // 开发环境
    baseURL: 'https://services.kenxinda.com/', // 正式环境
    timeout: 30000
});


// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (config.headers) {
        config.headers.Authorization = (store.state as StateAll).users.token;
    }

    if (config.data.files) {
        for (const i in config.headers) {
            if (i == 'Content-Type') {
                config.headers[i] = 'multipart/form-data';
            }
        }
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code == 401) {
        ElMessage.error('登录超时');
        store.commit('users/clearToken');
        setTimeout(() => {
            window.location.replace('/login');
        }, 1000);
    }
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

interface Data {
    [index: string]: unknown
}

interface Http {
    get: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
    post: (url: string, data?: Data, id?: Data, files?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
    put: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
    patch: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
    delete: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
    postUpload: (url: string, body?: Data, files?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
}

const http: Http = {
    get(url, data, config) {
        return instance.get(url, {
            params: data,
            ...config
        })
    },
    post(url, data, id, files, config) {
        return instance.post(url, {
            action: url,
            data,
            id,
            files,
        }, config)
    },
    put(url, data, config) {
        return instance.put(url, data, config)
    },
    patch(url, data, config) {
        return instance.patch(url, data, config)
    },
    delete(url, data, config) {
        return instance.delete(url, {
            data,
            ...config
        })
    },
    postUpload(url, body, files, config) {
        return instance.post(url, {
            body,
            files,
        }, config)
    }
}

export default http;