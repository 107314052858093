import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import type { StateAll } from '@/store'

const Login = () => import('@/views/Login/Login.vue')
const Home = () => import('@/views/Home/Home.vue')
const Overview = () => import('@/views/Overview/Overview.vue')
const Operator = () => import('@/views/Operator/Operator.vue')
const Area = () => import('@/views/Area/Area.vue')
// const Imei = () => import('@/views/Imei/Imei.vue')
const ActiveList = () => import('@/views/ActiveList/ActiveList.vue')
const WarrantyList = () => import('@/views/WarrantyList/WarrantyList.vue')
const Category = () => import('@/views/Category/Category.vue')
const Brand = () => import('@/views/Brand/Brand.vue')
const Attribute = () => import('@/views/Attribute/Attribute.vue')
const AttributeValue = () => import('@/views/AttributeValue/AttributeValue.vue')
const GoodsList = () => import('@/views/GoodsList/GoodsList.vue')
const GoodsDetails = () => import('@/views/GoodsList/GoodsDetails.vue')
const ActiveListDetails = () => import('@/views/ActiveList/ActiveListDetails.vue')
const AreaDetails = () => import('@/views/Area/AreaDetails.vue')
const User = () => import('@/views/UserManager/UserList.vue')
const Role = () => import('@/views/RoleManager/RoleList.vue')
const Password = () => import('@/views/Password/Password.vue')

// meta类型
declare module 'vue-router' {
  interface RouteMeta {
    menu?: boolean, // 是否显示左侧菜单
    title?: string, // 菜单标题
    icon?: string, // 菜单图标
    auth?: boolean, // 菜单访问权限
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/overview',
    meta: {
      menu: true,
      title: '用户统计',
      icon: 'document-copy',
      auth: true
    },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: Overview,
        meta: {
          menu: true,
          title: '概况',
          icon: 'document-copy',
          auth: true,
          name: 'home'
        }
      }, {
        path: 'operator',
        name: 'operator',
        component: Operator,
        meta: {
          menu: true,
          title: '运营商分布',
          icon: 'document-copy',
          auth: true,
          name: 'home'
        }
      }, {
        path: 'area',
        name: 'area',
        component: Area,
        meta: {
          menu: true,
          title: '地区分布',
          icon: 'document-copy',
          auth: true,
          name: 'home'
        }
      }, {
        path: 'activeList',
        name: 'activeList',
        component: ActiveList,
        meta: {
          menu: true,
          title: '激活列表',
          icon: 'document-copy',
          auth: true,
          name: 'imei'
        }
      }, {
        path: 'warrantyList',
        name: 'warrantyList',
        component: WarrantyList,
        meta: {
          menu: true,
          title: '激活与保修列表',
          icon: 'Tools',
          auth: true,
          name: 'repair'
        }
      }, {
        path: 'category',
        name: 'category',
        component: Category,
        meta: {
          menu: true,
          title: '品类管理',
          icon: 'document-copy',
          auth: true,
          name: 'goods'
        }
      }, {
        path: 'brand',
        name: 'brand',
        component: Brand,
        meta: {
          menu: true,
          title: '品牌管理',
          icon: 'document-copy',
          auth: true,
          name: 'goods'
        }
      }, {
        path: 'attribute',
        name: 'attribute',
        component: Attribute,
        meta: {
          menu: true,
          title: '属性管理',
          icon: 'document-copy',
          auth: true,
          name: 'goods'
        }
      }, {
        path: 'attributeValue',
        name: 'attributeValue',
        component: AttributeValue,
        meta: {
          menu: true,
          title: '属性值管理',
          icon: 'document-copy',
          auth: true,
          name: 'goods'
        }
      }, {
        path: 'goodsList',
        name: 'goodsList',
        component: GoodsList,
        meta: {
          menu: true,
          title: '商品列表',
          icon: 'document-copy',
          auth: true,
          name: 'goods'
        }
      }, {
        // path: 'goodsDetails/:id',
        path: 'goodsDetails',
        name: 'goodsDetails',
        component: GoodsDetails,
        meta: {
          path: 'goodsList',
          menu: true,
          title: '商品详情',
          icon: 'document-copy',
          auth: true,
          name: ''
        }
      }, {
        path: 'activeListDetails',
        name: 'activeListDetails',
        component: ActiveListDetails,
        meta: {
          path: 'activeList',
          menu: true,
          title: '激活商品详情',
          icon: 'document-copy',
          auth: true,
          name: ''
        }
      }, {
        path: 'areaDetails',
        name: 'areaDetails',
        component: AreaDetails,
        meta: {
          path: 'area',
          menu: true,
          title: '地区分布详情',
          icon: 'document-copy',
          auth: true,
          name: ''
        }
      }, {
        path: 'userList',
        name: 'userList',
        component: User,
        meta: {
          menu: true,
          title: '用户列表',
          icon: 'document-copy',
          auth: true,
          name: 'user'
        }
      }, {
        path: 'roleList',
        name: 'roleList',
        component: Role,
        meta: {
          menu: true,
          title: '角色列表',
          icon: 'document-copy',
          auth: true,
          name: 'user'
        }
      }, {
        path: 'password',
        name: 'password',
        component: Password,
        meta: {
          menu: true,
          title: '修改密码',
          icon: 'document-copy',
          auth: true,
          name: ''
        }
      }
    ]
  }, {
    path: '/imei',
    name: 'imei',
    // component: Imei,
    meta: {
      menu: true,
      title: 'IMEI激活管理',
      icon: 'copy-document',
      auth: true
    },
    children: []
  }, {
    path: '/repair',
    name: 'repair',
    meta: {
      menu: true,
      title: '激活与保修',
      icon: 'Tools',
      auth: true
    },
    children: []
  }, {
    path: '/goods',
    name: 'goods',
    // component: Imei,
    meta: {
      menu: true,
      title: '商品管理',
      icon: 'postcard',
      auth: true
    },
    children: []
  }, {
    path: '/user',
    name: 'user',
    meta: {
      menu: true,
      title: '用户管理',
      icon: 'UserFilled',
      auth: true
    },
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = (store.state as StateAll).users.token;
  if (to.meta.auth) {
    if (token) {
      next();
    } else {
      next('/login');
    }
  } else {
    if (token && to.path === '/login') {
      next('/');
    } else {
      next();
    }
  }
})
export default router
